
import { FormControl, InputLabel, ListSubheader, MenuItem, Select, Checkbox, SelectChangeEvent, FormControlLabel, Typography, useTheme, Button, Snackbar, AlertTitle, Alert, Slide, IconButton, Tooltip } from '@mui/material';
import { ChangeEventArgs, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Table, Toolbar } from '@syncfusion/ej2-react-richtexteditor';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { GetWorkerForRecordDTO, GroupAdminDTO, GroupDTO } from '../../../../ApiOld/ApiServerVov';
import InitSfRTBLocale from '../../../../Localization/SFRichEditLanguageIntializer';
import Dropdown from 'react-multilevel-dropdown';
import { Check, Construction, ContentCopy, Edit, Feedback, ListAlt, People, Psychology, QuestionMark } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '../Styles/AccordionStyles';

import { useLoginAdminStore } from '../../../../Contexts/LoginAdminZContext';
import { useDiaryCompletedWorksCopyStore } from '../../../../Contexts/DiaryCompletedWorksCopyZContext';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import HtmlConcurrencyViewerDialog from './HtmlConcurrencyViewerDialog';
import { useMediaPredicate } from 'react-media-hook';
import GptChatScene from '../../GptChat/GptChatScene';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { useLoginSubcontractorStore } from '../../../../Contexts/LoginSubcontractorZContext';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';
import { isValidHtml } from '../../../Shared/Functions/IsValidHtml';


interface CompletedWorksWidgetOldProps {
    buildingDiaryRecordId: number;
    completedWorksInput?: string;
    onHtmlChanged?: (html: string, group?: GroupDTO | null, admin?: GroupAdminDTO | null) => void;
    groups?: GroupDTO[];
    isExpanded?: boolean;
    showGroups?: boolean;
    isCopying: boolean;
    isInvestor?: boolean;
    concurrency?: string;
    copyCompletedWork: string;
    onExpandChange: (expanded: boolean) => void;
}
InitSfRTBLocale();
export default function CompletedWorksWidgetOld(props: CompletedWorksWidgetOldProps) {
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, completedWorksInput, onHtmlChanged, groups, isExpanded, showGroups, isCopying, isInvestor, concurrency, copyCompletedWork, onExpandChange } = props;
    const [completedWorks, setCompletedWorks] = useState<string>('');
    const [completedWorksConcurrency, setCompletedWorksConcurrency] = useState<string | undefined>();
    const [completedWorksCommonBackup, setCompletedWorksCommonBackup] = useState<string>('');
    const [selectedGroup, setSelectedGroup] = useState<GroupDTO | null>(null);
    const [localGroups, setLocalGroups] = useState<GroupDTO[] | null>(null);
    const [selectedPrivateContent, setSelectedPrivateContent] = useState<GroupAdminDTO | null>(null);
    const [sharedRecordSelected, setSharedRecordSelected] = useState<boolean>(true);
    const [concurrencyOpen, setConcurrencyOpen] = useState<boolean>(false);
    const [aiHelperOpen, setAiHelperOpen] = useState<boolean>(false);
    const [concurrencyOpenErrorInfo, setConcurrencyOpenErrorInfo] = useState<boolean>(false);
    const theme = useTheme();
    const { permissions } = useDiaryTabsContext();
    const { completedWorkStore } = useDiaryCompletedWorksCopyStore();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const { userId, load } = useLoginAdminStore();

    useEffect(() => {
        if (onHtmlChanged && copyCompletedWork !== '')
            onHtmlChanged(completedWorksCommonBackup + "<p>" + copyCompletedWork + "</p>");
    }, [copyCompletedWork, onHtmlChanged]);

    useEffect(() => {
        //reloadData();
    }, [buildingDiaryRecordId]);


    useEffect(() => {
        if (!userId) {
            new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).GetUser().then(v => {
                if (v)
                    load(v.userName!, v.userId!);
            })
        }
    }, []);

    function reloadData() {
        if (isCopying) {
            setCompletedWorks(completedWorkStore!);
        }
        else {
            if (buildingDiaryRecordId)
                if (isInvestor === undefined || isInvestor === false)
                    new APIVovHelper().GetCompletedWorksRecord(buildingDiaryRecordId).then((d) => {
                        if (d !== undefined)
                            setCompletedWorks(d);
                        else
                            setCompletedWorks('');

                    });
                else new APIVovInvestorHelper().GetCompletedWorksRecord(buildingDiaryRecordId).then((d) => {
                    if (d !== undefined)
                        setCompletedWorks(d);
                    else
                        setCompletedWorks('');
                });
        }
    }
    useEffect(() => {
        // reloadData();
        if (/* buildingDiaryRecordId &&  */completedWorksInput !== undefined) {
            setCompletedWorks(completedWorksInput);
            setCompletedWorksCommonBackup(completedWorksInput);
        }
        else {
            setCompletedWorks('');
            setCompletedWorksCommonBackup('');
        }

    }, [completedWorksInput]);

    useEffect(() => {
        setSelectedGroup(null);
        setSelectedPrivateContent(null);
        setSharedRecordSelected(true);

    }, [completedWorksInput]);

    useEffect(() => {
        setCompletedWorksConcurrency(concurrency);
        if (concurrency && concurrency.length > 0) {
            setConcurrencyOpenErrorInfo(true);
        }
    }, [concurrency]);

    useEffect(() => {

        // reloadData();
        // console.log(groups);
        if (groups)
            setLocalGroups(groups);

        if (permissions?.subcontractorsViewOnlyTheirPage) {
            if (groups && groups.length > 0) {
                let group = groups.find(p => p.members && p.members?.some(v => v.userId === userId));
                if (group) {
                    selectPrivateAdmin(group.members?.find(p => p.userId === userId)!, group);
                }
                else
                    setSelectedGroup(groups[0]);
            }
            else {
                setSelectedGroup(null);
            }
        }
        else {
            if (groups && groups.length > 0) {
                setSelectedGroup(groups[0]);
            }
            else {
                setSelectedGroup(null);
            }
        }
    }, [groups]);

    var toolbarSettings: object = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'CreateTable', 'Undo', 'Redo'
        ],
        type: 'Expand'
    }
    // const handleChange = (event: SelectChangeEvent<number | undefined>) => {

    //     if (sharedRecordSelected)
    //         setSharedRecordSelected(false);
    //     console.log(event.target.value);

    // };
    const selectPrivateAdmin = useCallback((member: GroupAdminDTO, group: GroupDTO) => {
        setSelectedPrivateContent(member);
        setSharedRecordSelected(false);
        setSelectedGroup(group);
        setCompletedWorks(member.content || '');
    }, []);

    const isReadOnlyEditor = useCallback((): boolean => {
        if ((selectedPrivateContent !== null && selectedPrivateContent.userId === userId && permissions?.subcontractorsViewOnlyTheirPage) || (sharedRecordSelected && permissions?.writeCommonCompletedWorks)) {
            return false;
        }
        return true;
    }, [selectedPrivateContent, userId, permissions, sharedRecordSelected]);


    const isVisibleEditor = useCallback((): boolean => {
        if (!sharedRecordSelected || (sharedRecordSelected && (permissions?.readCommonCompletedWorks || permissions?.writeCommonCompletedWorks)) || isInvestor) {
            return true;
        }
        return false;
    }, [sharedRecordSelected, permissions, isInvestor]);

    const countLines = useCallback((text: string): number => {
        const lines = text ? text.split('<p>') : [];
        return lines.length;
    }, []);

    const rtb = React.useRef<RichTextEditorComponent>(null);
    return (


        <Accordion sx={{ margin: 0 }} className='expander' expanded={isExpanded} onChange={(e, ex) => { onExpandChange(ex); }}>
            <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel2d-content" id="panel2d-header">
                <Construction color='secondary' className='accordion-icon' />
                <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.completedWorks')}</Typography>
                {(completedWorksCommonBackup === undefined || completedWorksCommonBackup === '') && <QuestionMark color='disabled' sx={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} />}
                {(completedWorksCommonBackup !== undefined && completedWorksCommonBackup !== '') && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />}
                {(completedWorksCommonBackup !== undefined && completedWorksCommonBackup !== '' && !isValidHtml(completedWorksCommonBackup)) && <Tooltip title={t('diary.completedWorksInputError')}><Feedback style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='error' /></Tooltip>}
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '16px' }}>
                    <div>
                        <FormControlLabel
                            value="end"
                            sx={{ marginBottom: '14px' }}
                            control={<Checkbox checked={sharedRecordSelected} sx={{ marginLeft: '10px' }} onChange={(e) => {
                                if (!sharedRecordSelected) {
                                    setSharedRecordSelected(true);
                                    setSelectedPrivateContent(null);
                                    setCompletedWorks(completedWorksCommonBackup);
                                }

                            }} icon={<People color='action' />} checkedIcon={<People color='primary' />} />}
                            label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: sharedRecordSelected ? theme.palette.primary.main : theme.palette.secondary.main }}>{t('diary.sharedEntry')}</Typography>}
                            labelPlacement="end"
                        />

                        {showGroups && localGroups && (isInvestor === undefined || isInvestor === false) && <Dropdown
                            position='left'
                            /* menuClassName='multilevel-dropdown-menu' */
                            title=
                            {<div style={{ color: '#404040', display: 'flex', fontFamily: 'Roboto' }}>
                                <ListAlt />
                                <div style={{ marginTop: '6px', marginLeft: '4px' }}>{selectedPrivateContent !== null && selectedGroup !== null ? selectedPrivateContent.name + ' (' + selectedGroup.groupName + ')' : t('diary.PickPrivateRecord')}</div></div>}
                        >
                            {/* <div style={{ maxHeight: '300px', overflowY: 'auto' }}> */}
                            {localGroups.map((group) => {
                                return <Dropdown.Item key={group.groupId}>
                                    <div style={{ fontFamily: 'Roboto' }} >{group.groupName}</div>
                                    <Dropdown.Submenu /* className='multilevel-dropdown-submenu' */ position='right'>
                                        {group.members && group.members.map((member) => {
                                            return <Dropdown.Item key={member.userId} onClick={(e) => { selectPrivateAdmin(member, group) }}>
                                                <div style={{ fontFamily: 'Roboto' }} >{member.name} {member.content !== undefined && member.content !== '' && <Check sx={{ width: '18px', height: '16px', marginTop: '-2px' }} color='success' />}</div>
                                            </Dropdown.Item>;
                                        })}
                                    </Dropdown.Submenu>
                                </Dropdown.Item>

                            })}
                            {/* </div> */}

                        </Dropdown>}
                        {selectedPrivateContent !== null && <Button onClick={(e) => {
                            if (onHtmlChanged) {
                                if (rtb && rtb.current)
                                    onHtmlChanged(completedWorksCommonBackup + rtb.current.getHtml());
                                else
                                    onHtmlChanged(completedWorksCommonBackup + completedWorks);
                            }
                            // backu(completedWorks + rtb.current!.getHtml());
                        }} sx={{ marginLeft: '8px', marginBottom: '18px' }} startIcon={<ContentCopy />} disabled={!(permissions && permissions.writeCommonCompletedWorks)}>{t('diary.copyToShared')}</Button>}
                    </div>
                    {isInvestor !== true && <div style={{ position: 'relative', right: '0px', top: '0px' }}><Button color={aiHelperOpen ? "success" : "info"} startIcon={<Psychology />} onClick={e => setAiHelperOpen(!aiHelperOpen)}>{biggerThan700 ? t('aiHelper') : ''}</Button></div>}
                    {completedWorksConcurrency !== undefined && <Button sx={{ marginLeft: '10px', marginBottom: '19px' }} variant='contained' startIcon={<Edit />} onClick={(e) => { setConcurrencyOpen(true) }}>{biggerThan700 ? t('buildings.showChangedRecords') : ''}</Button>}

                </div>
                {aiHelperOpen &&
                    <div style={{ width: '100%' }}>
                        <GptChatScene onCopy={(text) => {
                            if (onHtmlChanged)
                                onHtmlChanged(completedWorksCommonBackup !== null ? completedWorksCommonBackup + '<p>' + text + '</p>' : '' + '<p>' + text + '</p>');
                        }} /></div>}

                <div>
                    {!isValidHtml(completedWorks) && <Alert severity="error">{'chyba'}</Alert>}
                    {/* {isValidHtml(completedWorks) === true && <Alert severity="success">{'ok'}</Alert>} */}
                </div>
                {isVisibleEditor() && !isReadOnlyEditor() && (!(sharedRecordSelected) || (permissions && permissions.writeCommonCompletedWorks)) &&
                    <RichTextEditorComponent
                        saveInterval={50}
                        ref={rtb} actionBegin={(e) => {
                            if (e.type === 'drop' || e.type === 'dragstart') {
                                e.cancel = true;
                            }
                        }}
                        toolbarSettings={toolbarSettings} height={(completedWorks !== null && completedWorks.length > 500) || countLines(completedWorks) > 10 ? 600 : 200} locale={i18n.language} value={completedWorks} change={(eve: ChangeEventArgs) => {
                            setCompletedWorks(eve.value);
                            if (selectedPrivateContent === null) {
                                setCompletedWorksCommonBackup(eve.value);
                                if (onHtmlChanged)
                                    onHtmlChanged(eve.value ? eve.value : '');
                            }
                            else {
                                if (onHtmlChanged)
                                    onHtmlChanged(eve.value ? eve.value : '', selectedGroup, selectedPrivateContent);
                            }

                        }} >
                        <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table]} />
                    </RichTextEditorComponent>}
                {isVisibleEditor() && isReadOnlyEditor() && (!(sharedRecordSelected) || (permissions && permissions?.writeCommonCompletedWorks)) && <div style={{ width: '100%', height: '180px', backgroundColor: 'white', maxHeight: '400px', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: completedWorks }}></div>}
                {!isVisibleEditor() && <div style={{ width: '100%', height: '180px', backgroundColor: 'white', maxHeight: '400px', overflow: 'auto' }} >{t('noViewingPermissions')}</div>}
                {isVisibleEditor() && sharedRecordSelected && (permissions === undefined || permissions?.writeCommonCompletedWorks === undefined || permissions?.writeCommonCompletedWorks === false) && <div style={{ width: '100%', height: '180px', backgroundColor: 'white', maxHeight: '400px', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: completedWorks }}></div>}
                <Snackbar
                    TransitionComponent={(propsy) => { return <Slide {...propsy} direction='left' /> }}
                    open={concurrencyOpenErrorInfo}
                    autoHideDuration={5000}
                    onClose={(e) => { setConcurrencyOpenErrorInfo(false) }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
                    <Alert onClose={(e) => { setConcurrencyOpenErrorInfo(false) }} severity="warning">
                        <AlertTitle>{t('dataChanged')}</AlertTitle>
                        <span>{t('dataChangedReloadAndFillAgain')}</span>
                    </Alert>
                </Snackbar>
                <HtmlConcurrencyViewerDialog open={concurrencyOpen} onClose={() => { setConcurrencyOpen(false) }} myHtml={completedWorks} concurrencyHtml={completedWorksConcurrency} />
            </AccordionDetails>
        </Accordion>
    );

}