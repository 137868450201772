import { BuildOutlined, CalendarMonth, Clear, Close, CloseFullscreen, CompressOutlined, Fullscreen, GroupsOutlined, PrecisionManufacturingOutlined, Route, WidgetsOutlined } from '@mui/icons-material';
import { Box, Button, Checkbox, Dialog, DialogTitle, FormControlLabel, FormGroup, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { Marker } from 'react-image-marker';
import withLoading, { WithLoadingProps } from '../../Shared/Loader';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
//import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import DiarySelect from './Widgets/DiarySelect'; import { TabPanel } from '../../Shared/Tabs/TabPanel';
import tabProps from '../../Shared/Tabs/TabProps';
import WorkersWidget from './Widgets/WorkersWidget';
import MachinesWidget from './Widgets/MachinesWidget';
import MaterialsWidget from './Widgets/MaterialsWidget';
import { GetWorkerForRecordDTO, GetMaterialsForRecordTableDTO, GetMachinesForRecordTableDTO, GroupDTO, GroupAdminDTO, SaveMachinesTableReqDTO, SaveMaterialsTableReqDTO, SaveWorkerReqDTO, SubDiaryDTO } from '../../../ApiOld/ApiServerVov';
import CompletedWorksWidget from './Widgets/CompletedWorksWidget';
import { useParams } from 'react-router';
import { useDiaryWorkersCopyStore } from '../../../Contexts/DiaryWorkersCopyZContext';
import { useDiaryMaterialsCopyStore } from '../../../Contexts/DiaryMaterialsCopyZContext';
import { useDiaryMachinesCopyStore } from '../../../Contexts/DiaryMachinesCopyZContext';
import { useDiaryCompletedWorksCopyStore } from '../../../Contexts/DiaryCompletedWorksCopyZContext';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as RNG from 'react-date-range';
import cs from 'date-fns/locale/cs';
import sk from 'date-fns/locale/sk';
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en-US';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';
import WorkersCopyWidget from './Widgets/WorkersCopyWidget';
import MachinesCopyWidget from './Widgets/MachineCopyWidget';
import MaterialsCopyWidget from './Widgets/MaterialsCopyWidget';
import { load } from '@syncfusion/ej2-react-grids';

export interface DiaryRecordCopyDialogProps extends WithLoadingProps {
    open: boolean;
    diaryId: number | undefined;
    diaryRecordId: number | undefined;
    workersInput?: GetWorkerForRecordDTO[];
    machinesInput?: GetMachinesForRecordTableDTO[];
    materialsInput?: GetMaterialsForRecordTableDTO[];
    completedWorksInput?: string;
    onClose: (refresh: boolean) => void;
    onRefresh: (data: any) => void;
    isSubdodavatel?: boolean;
}


//
function DiaryRecordCopyDialog(props: DiaryRecordCopyDialogProps) {

    const { onClose, diaryId, diaryRecordId, workersInput, machinesInput, materialsInput, completedWorksInput, open, onRefresh, isSubdodavatel, fireLoading, stopLoading } = props;
    const { t, i18n } = useTranslation();
    const biggerThan600 = useMediaPredicate("(min-width: 600px)");
    const [diary, setDiary] = useState<number | undefined>(undefined);
    const [diaryRecord, setDiaryRecord] = useState<number | undefined>(undefined);
    const [tab, setTab] = React.useState(0);
    const isLoaded = useRef<boolean>(false);
    const [workers, setWorkers] = React.useState<GetWorkerForRecordDTO[]>([]);
    const [materials, setMaterials] = React.useState<GetMaterialsForRecordTableDTO[]>([]);
    const [machines, setMachines] = React.useState<GetMachinesForRecordTableDTO[]>([]);
    const [subdiaries, setSubdiaries] = React.useState<GroupAdminDTO[]>([]);
    const [completedWorks, setCompletedWorks] = React.useState<string | undefined>();
    const [onlyWorkDays, setOnlyWorkDays] = React.useState<boolean>(false);
    const [isCalendarOpen, setIsCalendarOpen] = React.useState<boolean>(false);

    const [dateRange, setDateRange] = React.useState<RNG.Range[]>([
        {
            startDate: new Date((new Date()).valueOf() + 1000 * 3600 * 24),
            endDate: new Date((new Date()).valueOf() + 1000 * 3600 * 24),
            key: 'selection'
        }
    ]);

    // persistent store
    const { workersStore, loadWorkersStore } = useDiaryWorkersCopyStore();
    const { materialsStore, loadMaterialsStore } = useDiaryMaterialsCopyStore();
    const { machinesStore, loadMachinesStore } = useDiaryMachinesCopyStore((state) => ({ machinesStore: state.machinesStore, loadMachinesStore: state.loadMachinesStore }));
    const { completedWorkStore, loadCompletedWorksStore } = useDiaryCompletedWorksCopyStore();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    const params = useParams();

    useEffect(() => {
        stopLoading!();
        setDiary(diaryId);
        setDiaryRecord(diaryRecordId);

        setWorkers(workersStore!);
        setMachines(machinesStore!);
        setMaterials(materialsStore!);
        setCompletedWorks(completedWorkStore!);
    }, []);



    useEffect(() => {
        setWorkers(workersStore!);
    }, [workersStore]);
    useEffect(() => {
        setMachines(machinesStore!);
    }, [machinesStore]);
    useEffect(() => {
        setMaterials(materialsStore!);
    }, [materialsStore]);
    useEffect(() => {
        setCompletedWorks(completedWorkStore!);
    }, [completedWorkStore]);

    useEffect(() => {
        stopLoading!();
        setDiary(diaryId);
    }, [diaryId]);
    useEffect(() => {
        stopLoading!();
        setDiary(diaryId);
    }, [diaryRecordId]);
    useEffect(() => {
        stopLoading!();
        if (diaryRecordId !== undefined && open)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetFullDailyRecord(diaryRecordId, params['domain']!, false, false, false).then((data_diary) => {
                if (data_diary !== undefined) {
                    if (data_diary.workers)
                        loadWorkersStore(data_diary.workers);
                    else
                        loadWorkersStore([]);

                    if (data_diary.materialsTable)
                        loadMaterialsStore(data_diary.materialsTable);
                    else
                        loadMaterialsStore([]);

                    if (data_diary.machinesTable)
                        loadMachinesStore(data_diary.machinesTable);
                    else
                        loadMachinesStore([]);

                    loadCompletedWorksStore(data_diary.completedWork!);
                }
            });
    }, [open]);

    function setDefaultState() {
    }

    const handleMachinesChanged = (indexes: number[]) => {
        machines.forEach((m, i) => {
            if (indexes.some(p => p === i))
                m.selected = true;
            else
                m.selected = false;
        });
        loadMachinesStore(machines);
    }

    const handleMaterialsChanged = (indexes: number[]) => {
        materials.forEach((m, i) => {
            if (indexes.some(p => p === i))
                m.selected = true;
            else
                m.selected = false;
        });
        loadMaterialsStore(materials);
    }

    const handleWorkersChanged = (indexes: number[]) => {
        workers.forEach((w, i) => {
            if (indexes.some(p => p === i))
                w.selected = true;
            else
                w.selected = false;
        });
        loadWorkersStore(workers);
    }

    function clearStores() {
        loadWorkersStore(undefined);
        loadMaterialsStore(undefined);
        loadMachinesStore(undefined);
        loadCompletedWorksStore(undefined);
    }

    function onCopyClicked() {
        if (dateRange![0].startDate !== undefined && dateRange![0].endDate !== undefined) {
            let mach = new Array<SaveMachinesTableReqDTO>();
            machines.forEach((item) => {
                if (item.selected)
                    mach.push(new SaveMachinesTableReqDTO({ isShared: item.isShared, name: item.name, code: item.code }));
            });

            let mat = new Array<SaveMaterialsTableReqDTO>();
            materials.forEach((item) => {
                if (item.selected)
                    mat.push(new SaveMaterialsTableReqDTO({ isShared: item.isShared, name: item.name, amount: item.amount, unit: item.unit }));
            });

            let worker = new Array<SaveWorkerReqDTO>();
            workers.forEach((item) => {
                if (item.selected)
                    worker.push(new SaveWorkerReqDTO({ isShared: item.isShared, name: item.name, hours: item.workHours, code: item.code, groupName: item.groupName }));
            });
            let save = (new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false)).copyDiaryRecord(diaryId!, dateRange![0].startDate, dateRange![0].endDate, onlyWorkDays, completedWorks ? completedWorks : '', mach, mat, worker).then((saved) => {
                if (saved) {
                    clearStores();
                    onClose(true);
                }
            })
        }

    }

    const [fullScreen, setFullScreen] = useState(false);
    function onClosed() { setDefaultState(); clearStores(); setDefaultDate(); onClose(false); }

    interface CustomMarker extends Marker {
        type: "success" | "error" | "warning";
        content: string;
        deviceId?: string | number;
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };
    const getVisibilityStyle = (hiddenCondition: boolean): any => {
        if (hiddenCondition) {
            return {
                visibility: 'hidden',
                height: 0,
            };
        }
        return {
            visibility: 'visible',
            height: 'inherit',
        };
    };
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    function setDefaultDate() {
        setDateRange([
            {
                startDate: undefined,
                endDate: undefined,
                key: 'selection'
            }
        ]);
    }

    return (
        <Dialog fullWidth maxWidth="lg" fullScreen={fullScreen} open={open} onClose={() => { onClosed() }} sx={{ marginTop: '80px' }}>
            <DialogTitle height='40px' sx={{ zIndex: "255", backgroundColor: '#F5F5F5' }}>
                <div style={{ backgroundColor: '#F5F5F5', zIndex: "200" }}>
                    <div style={{ float: 'left', display: 'flex' }}>
                        <Typography variant='h1' fontWeight='bold' fontSize={20}>{t('copyingOfRecords')}</Typography>
                    </div>
                    <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>
                        <div style={{ marginTop: '-14px' }}>
                            <IconButton onClick={() => {
                                setFullScreen(!fullScreen);
                            }} >
                                {fullScreen ? <CloseFullscreen /> : <Fullscreen />}
                            </IconButton>
                            <IconButton onClick={onClosed} >
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </DialogTitle>
            <div style={{ display: 'flex-inline', flexDirection: 'column', flexWrap: 'wrap', backgroundColor: '#F5F5F5', minHeight: '600px' }} >
                <div style={{ display: 'flex', margin: '20px' }}>
                    <div style={{ margin: '20px', width: '65%' }}>
                        <DiarySelect isSubdodavatel={isSubdodavatel} onChange={(e) => { setDiary(e?.id) }} selectedDiaryId={diary} />
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs variant='fullWidth' value={tab} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab icon={<GroupsOutlined />} iconPosition="start" label={biggerThan700 ? t('diary.workersL') : ''} {...tabProps(0)} />
                                    <Tab icon={<PrecisionManufacturingOutlined />} iconPosition="start" label={biggerThan700 ? t('diary.machinesL') : ''} {...tabProps(1)} />
                                    <Tab icon={<WidgetsOutlined />} iconPosition="start" label={biggerThan700 ? t('diary.materialsL') : ''} {...tabProps(2)} />
                                    <Tab icon={<BuildOutlined />} iconPosition="start" label={biggerThan700 ? t('diary.completedWorksL') : ''} {...tabProps(3)} />

                                </Tabs>
                            </Box>
                            <TabPanel value={tab} index={0}>
                                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '8px', marginBottom: '-64px' }}>
                                    <div style={getVisibilityStyle(tab !== 0)}>
                                        <div style={{ margin: '-20px' }}>
                                            <WorkersCopyWidget
                                                isLocked={false}
                                                isCopying={true}
                                                onSelectionChanged={handleWorkersChanged}
                                                workersInput={workers}
                                                buildingDiaryRecordId={diaryRecordId}
                                                showGroups={false}
                                                showWorkHours={true}
                                                isSubdodavatel={isSubdodavatel}
                                            />
                                        </div>
                                    </ div>
                                </Box>
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '8px', marginBottom: '-64px' }}>
                                    <div style={{ margin: '-20px' }}>
                                        <MachinesCopyWidget isCopying={true} onSelectionChanged={handleMachinesChanged} buildingDiaryRecordId={diaryRecordId} machinesNote={undefined} isSubdodavatel={isSubdodavatel} />
                                    </div>
                                </Box>
                            </TabPanel>
                            <TabPanel value={tab} index={2}>
                                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '8px', marginBottom: '-64px' }}>
                                    <div style={{ margin: '-20px' }}>
                                        <MaterialsCopyWidget isCopying={true} onSelectionChanged={handleMaterialsChanged} materialsNote={undefined} materialsInput={materials} buildingDiaryRecordId={diaryRecordId} isSubdodavatel={isSubdodavatel} />
                                    </div>

                                </Box>
                            </TabPanel>
                            <TabPanel value={tab} index={3}>
                                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '80%', marginTop: '8px', marginBottom: '16px' }}>
                                    {/* diaryRecord !== undefined && */ <CompletedWorksWidget onExpandChange={() => { }} copyCompletedWork='' isExpanded isSubdodavatel={isSubdodavatel} isCopying={true} showSubdiaries={false} onHtmlChanged={(text, subdiary) => {
                                        if (subdiary === null || subdiary === undefined) {
                                            loadCompletedWorksStore(text);
                                            setCompletedWorks(completedWorkStore);
                                        }
                                        else {
                                            let subNew = subdiaries.find(x => x.contentId === subdiary.contentId);
                                            if (subNew) {
                                                let subToUse = new GroupAdminDTO({
                                                    contentId: subNew.contentId, name: subNew.name,
                                                    content: text, userId: subNew.userId,
                                                    changed: true
                                                })
                                                setSubdiaries([...subdiaries.filter(x => x.contentId !== subdiary.contentId), subToUse])
                                            }

                                        }
                                    }}
                                        completedWorksInput={completedWorks} buildingDiaryRecordId={diaryRecord!}
                                        subdiaries={subdiaries}
                                    />}
                                    {/*  {diaryRecord === undefined && <Typography>
                                        bad data
                                    </Typography>} */}
                                </Box>
                            </TabPanel>

                        </Box>
                    </div>
                    <div style={{ display: 'flex', margin: '5px', borderRight: '1px solid black' }}>
                    </div>
                    <div style={{ margin: '20px', width: '30%' }}>
                        <div style={{ display: 'flex', margin: '5px', borderBottom: '1px solid' }}>
                            <Typography sx={{ fontWeight: 'bold', minWidth: '80px', marginRight: '10px', marginLeft: "auto" }}>
                                {dateRange[0]!.startDate === undefined ? '               ' : dateRange[0]!.startDate === dateRange[0]!.endDate ? dateRange[0]!.startDate?.toLocaleDateString(i18n.language) : dateRange[0]!.startDate?.toLocaleDateString(i18n.language) + ' - ' + dateRange[0]!.endDate?.toLocaleDateString(i18n.language)}
                            </Typography>
                            {
                                dateRange[0]!.startDate && <IconButton sx={{ marginTop: '-8px' }} onClick={() => {
                                    setDefaultDate();
                                }}>
                                    <Clear />
                                </IconButton>
                            }
                            <IconButton sx={{ marginTop: '-8px' }} onClick={() => {
                                setIsCalendarOpen(true);
                            }}>
                                <CalendarMonth />
                            </IconButton>
                        </div>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={onlyWorkDays} onChange={(e, r) => { setOnlyWorkDays(r); }} />} label={t('onlyWorkDays')} />
                        </FormGroup>

                        <div style={{ margin: 'auto' }}>
                            <Button onClick={() => { onCopyClicked() }} sx={{ backgroundColor: '#FFC600', color: 'black', justifyContent: "flex-start", margin: "16px", marginBottom: "32px" }}>{t('diary.copyDiary')}</Button>
                        </div>
                    </div>
                </div>
                <Dialog open={isCalendarOpen} onClose={() => { setIsCalendarOpen(false); }}>
                    <div>
                        <RNG.DateRange editableDateInputs={true} moveRangeOnFirstSelection={false} ranges={dateRange} onChange={item => { setDateRange([item.selection]); console.log('chac', item.selection); }} locale={i18n.language == 'cs' ? cs : (i18n.language == 'pl' ? pl : (i18n.language == 'sk' ? sk : en))} />
                        <div style={{ display: 'flex', margin: '5px', borderRight: '1px solid black' }}>
                            <Button onClick={() => { setIsCalendarOpen(false); }} style={{
                                marginLeft: 'auto', marginRight: '10px', border: '1px solid', borderColor: 'lightgray'

                            }} aria-describedby='btnOk'>
                                <Typography color='#FFC600'>OK</Typography>
                            </Button>
                            <Button onClick={() => { setDefaultDate(); setIsCalendarOpen(false); }} style={{ marginRight: 'auto', border: '1px solid', borderColor: 'lightgray' }} aria-describedby='btnOk'>
                                <Typography color='#FFC600'>{t('Cancel')}</Typography>
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </div >
        </Dialog >
    );
};

export default withLoading(DiaryRecordCopyDialog);
